import type { RsPathType } from "~/types/common";
import { createMemo, For, Show } from "solid-js";

import "./Pagination.css";
import { urlRs } from "~/utils/url";
import { A } from "@solidjs/router";
import { ITEMS_PER_PAGE } from "~/utils/constants";
import type {
  ItemReference,
  ProgramRefWithLocation,
} from "~/types/drupal_jsonapi";

export type ProgramPaginationStore = {
  sort: "cp" | "ville";
  currentPage: number;
  slices: ProgramRefWithLocation[][];
  displayedPrograms: ProgramRefWithLocation[];
};

export type ItemReferencePaginationStore = {
  currentPage: number;
  slices: ItemReference[][];
  displayedItems: ItemReference[];
};

export function Pagination(props: {
  currentPage: number;
  totalPages: number;
  url: string;
  type?: RsPathType;
  scrollTo?: string;
}) {
  const pages = createMemo(() => {
    if (props.url) {
      const url = new URL(props.url, import.meta.env.VITE_COG_FRONT_BASE_URL);
      const path = url.pathname;
      return Array.from(Array(props.totalPages).keys()).map((i) => {
        const searchParams = new URLSearchParams(url.searchParams);

        // If on the first page, remove the page query param
        if (i !== 0) {
          searchParams.set("page", i.toString());
        }

        const newPathname = searchParams.toString()
          ? `${path}?${searchParams.toString()}`
          : path;

        return {
          label: i + 1, // Drupal is 0-based, but human are not.
          isCurrent: i === props.currentPage,
          href: newPathname,
        };
      });
    }
  });

  return (
    <nav class="pagination" data-test="pager">
      <ul>
        <For each={pages()}>
          {(page, index) => (
            <>
              <li>
                <Show
                  when={!page.isCurrent}
                  fallback={
                    <span class="current" data-test="current">
                      {page.label}
                    </span>
                  }
                >
                  <A
                    noScroll
                    onClick={() => {
                      if (props.scrollTo) {
                        scrollTo(props.scrollTo);
                      }
                    }}
                    href={props.type ? urlRs(props.type, page.href) : page.href}
                    rel={
                      index() === props.currentPage - 1
                        ? "prev"
                        : index() === props.currentPage + 1
                          ? "next"
                          : undefined
                    }
                  >
                    {page.label}
                  </A>
                </Show>
              </li>
            </>
          )}
        </For>
      </ul>
    </nav>
  );
}

export function paginationSlices<T>(
  items: T[],
  opts?: { insertPromoCard?: boolean; itemsPerPage?: number },
): T[][] {
  if (items.length > 0) {
    const itemsPerPage = opts?.itemsPerPage || ITEMS_PER_PAGE;
    return Array.from(
      {
        length: Math.ceil(items!.length / itemsPerPage),
      },
      (_, i) => {
        if (opts?.insertPromoCard) {
          items!.splice(6, 0, items![5]);
        }

        return items!.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage);
      },
    );
  } else {
    return [[]];
  }
}

function scrollTo(selector: string) {
  const target = document.querySelector(selector);
  if (target) {
    target.scrollIntoView({
      behavior: "smooth",
    });
  }
}
